import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class TN1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="TN1"
          description="View route information and buy your ticket for the TN1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">TN1 service</h1>
              <p>York Street/Leeds Bus Station - Leeds City College, Temple Newsam</p>
              <p>Leeds City College, Temple Newsam - York Street/Leeds Bus Station</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="TN1 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1XBPm4seV34VKAc2jUmtfTbDQQMhRhWE&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Leeds City College, Temple Newsam
                </p>
                <p>
                  From York Street/ Leeds Bus Station Direct to Temple Newsam Bus turning Circle
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="TN1 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1Vqlb1jz52xBpuDmeo-HgLAtOvt8QQ5o&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Leeds City College, Temple Newsam
                </p>
                <p>
                  From Temple Newsam bus turning circle Direct to York Street
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>York Street outside Leeds Bus Station (Bus Stop C)</td>
                      <td>DEP</td>
                      <td>0840</td>
                    </tr>
                    <tr>
                      <td>Leeds City College, Temple Newsam – Bus Turning Circle</td>
                      <td>ARR</td>
                      <td>0855</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Leeds City College, Temple Newsam – Bus Turning Circle</td>
                      <td>DEP</td>
                      <td>1630</td>
                    </tr>
                    <tr>
                      <td>York Street opposite Leeds Bus Station</td>
                      <td>ARR</td>
                      <td>1655</td>
                    </tr>
                  </tbody>
                </table>

                <h2 style={{ margin: '0 0 20px 0'}}>Fares</h2>
                <p>Passes are issued directly by Leeds City College.</p>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default TN1ServicePage
